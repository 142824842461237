import request from '@/utils/request'
// 首页列表
export function queryDataList (data) {
  return request({
    url: '/tree-item/list',
    method: 'post',
    data,
  })
}
// 保存
export function saveDataItem (data) {
  return request({
    url: '/photograph/save',
    method: 'post',
    data,
  })
}
// 苗单拍照保存苗木信息
export function varietySaveItem (data) {
  return request({
    url: '/photograph/variety/save-item',
    method: 'post',
    data,
  })
}
// 模板信息
export function queryDataDetail (data) {
  return request({
    url: '/photograph/data',
    method: 'post',
    data,
  })
}
// 详情
export function queryItemDataDetail (data) {
  return request({
    url: '/index/item-data',
    method: 'post',
    data,
  })
}
//更改状态
export function changeDataTag (data) {
  return request({
    url: '/tree-item/change-tag',
    method: 'post',
    data,
  })
}

// 删除
export function delDataItem (data) {
  return request({
    url: '/tree-item/delete',
    method: 'post',
    data,
  })
}

// 植物选型
export function saveTreeTypeDataItem (data) {
  return request({
    url: '/treeTypeSelection/record-save',
    method: 'post',
    data,
  })
}